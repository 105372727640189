import request from "@/utils/request";

export function cardApply(data) {
  return request({
    url: `/credit/card/apply`,
    method: "POST",
    headers: {
      needToken: true,
      unDecode: 1,
    },
    data,
  });
}

export function applyRecords(data) {
  return request({
    url: `/credit/card/applyRecords`,
    method: "POST",
    headers: {
      needToken: true,
      loading: false,
      unDecode: 1,
    },
    data,
  });
}

<template>
  <div>
    <BankItem
      :bankInfo="bankItem"
      direction="vertical"
      class="bor-bot"
      @click="handleClick(bankItem.bankType)"
    />
    <div class="more-box">
      <div class="more-tit">更多推荐</div>
      <BankItem
        v-for="(item, index) in bankList"
        :key="index"
        :bankInfo="item"
        @click="handleClick(item.bankType)"
      />
    </div>
  </div>
</template>

<script>
import creditCard from "@/utils/creditCard";
import * as Api_CardApplication from "@/api/cardApplication";

export default {
  name: "CardApplication",
  data() {
    return {
      bankItem: {},
      bankList: [],
      params: {
        source: 1,
        bankType: null,
      },
    };
  },
  created() {
    this.bankItem = creditCard[0];
    this.bankList = creditCard.slice(1, 3);
    let url = `${window.location.origin}/progressQuery`;
    this.setRightBtn("进度查询", url);
  },
  methods: {
    handleClick(index) {
      this.params.bankType = index;
      Api_CardApplication.cardApply(this.params).then((response) => {
        if (response.code === 200) {
          window.location.href = response.data.applyUrl;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bor-bot {
  border-bottom: 0.24rem solid #f8f8f8;
}
.more-box {
  padding-bottom: 0.6rem;
}
.more-tit {
  padding: 0.36rem 0.6rem 0;
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #3a3a3a;
  line-height: 0.44rem;
}
</style>

import gdCardImg from "@/assets/images/cardApplication/card_gd.png";
import paCardImg from "@/assets/images/cardApplication/card_pa.png";
import zxCardImg from "@/assets/images/cardApplication/card_zx.png";
import gdBankLogo from "@/assets/images/cardApplication/logo_gd.png";
import paBankLogo from "@/assets/images/cardApplication/logo_pa.png";
import zxBankLogo from "@/assets/images/cardApplication/logo_zx.png";

const creditCard = [
  {
    cardImg: zxCardImg,
    bankLogo: zxBankLogo,
    bankName: "中信银行信用卡",
    bankTag: ["或免年费", "审批快", "刷卡返现"],
    bankTips:
      "官方在线申请，周三，周六五折优惠随心享，9积分即可兑换众多礼品!外卖生鲜，电商购物优惠享不尽",
    bankBtnTest: "免费申请",
    bankType: 2,
  },
  {
    cardImg: paCardImg,
    bankLogo: paBankLogo,
    bankName: "平安银行信用卡",
    bankTag: ["24小时在线办理", "首年免年费", "1分钟极速办卡"],
    bankTips:
      "精选好卡，办卡快、额度高、免年费，提供涵盖“消费、居家、旅行”全方位服务",
    bankBtnTest: "免费申请",
    bankType: 3,
  },
  {
    cardImg: gdCardImg,
    bankLogo: gdBankLogo,
    bankName: "光大银行信用卡",
    bankTag: ["1步完成申卡", "首年免年费"],
    bankTips:
      "核卡首年免年费,刷6笔免次年权益多多，刷卡积分享不停，高品质积分商城等你来兑",
    bankBtnTest: "免费申请",
    bankType: 1,
  },
];

export default creditCard;
